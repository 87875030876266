.popover {
    max-width: 336px;
    border-color: var(--vwui-color-neutral-3);
}

.popover-body {
    padding: 0;
}

.popover-image-download {
    padding: var(--vwui-size-spacing-sm);
    color: var(--vwui-color-dark);
    font-size: var(--vwui-size-font-base);
    max-height: 600px;
    overflow-y: auto;

    &__message {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 56px;
        border: var(--vwui-border-light);
        border-radius: var(--vwui-size-radius-sm);
        padding: 0 var(--vwui-size-spacing-sm);
        font-weight: var(--vwui-font-weight-semibold);
        margin-bottom: var(--vwui-size-spacing-base);
    }

    &__content {
        margin-bottom: var(--vwui-size-spacing-lg);
    }

    &__action {
        vwui-button {
            display: block;
        }
    }

    &__list {
        list-style: none;
    }

    &__list-item {
        margin-bottom: var(--vwui-size-spacing-xxs);
    }

    &__list-header {
        font-weight: var(--vwui-font-weight-semibold);
        font-size: var(--vwui-size-font-xs);
        color: var(--vwui-color-neutral-1);
        text-transform: uppercase;
        padding: var(--vwui-size-spacing-xxs) var(--vwui-size-spacing-xs);
        margin-top: var(--vwui-size-spacing-xs);
    }

    &__list-button {
        display: block;
        width: 100%;
        padding: var(--vwui-size-spacing-xxs) var(--vwui-size-spacing-xs);
        background: none;
        border: 0;
        border-radius: var(--vwui-size-radius-sm);
        text-align: left;

        &:hover {
            background: var(--vwui-color-neutral-4);
        }
    }
}
