html {
  font-size: $beel-font-size-root;
}

body {
  font-family: $beel-font-body;
  background: $beel-color-bg-body;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior: none; // Disable overscroll
  transition: margin-right $beel-transition-ease $beel-transition-timing;
  will-change: margin-right;
  background: $beel-color-bg-base;

  overflow-x: hidden;

  &.aside-open {
    margin-right: $beel-size-aside;
  }

  &.actionbar-visible {
    .beel-container {
      padding-bottom: $beel-size-actionbar;
    }
  }
}

strong {
  font-weight: $beel-font-weight-secondary;
}
