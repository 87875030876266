.app-login-overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    overflow: hidden;

    &__container {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        z-index: 10;
        max-width: rem(630px);
        text-align: center;
        font-weight: 500;
        animation-delay: 200ms;
        animation: 1s fadein ease-in-out;
    }

    &__button {
        border-radius: rem(4px);
        background: $beel-color-bg-base;
        text-align: center;
        padding: rem(15px) rem(55px);
        font-size: rem(20px);
        font-weight: 500;
        color: $beel-color-text-login-green;
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, .24);

        &:hover {
            background: $beel-color-bg-hover;
        }
    }

    &__title {
        color: white;
        font-size: rem(48px);
        font-weight: 500;
        text-shadow: #000 1px 1px 10px;
    }

    &__description {
        color: white;
        font-size: rem(24px);
        letter-spacing: 0.5px;
        text-shadow: #000 1px 1px 10px;
    }

    &__backdrop {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-image: url(/assets/login-background.jpg);
        background-size: cover;
        background-position: center top;
        animation: 30s slowscale ease-in-out;
        will-change: transform;
    }
}

@keyframes slowscale {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.08);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes fadein {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
