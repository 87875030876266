html, body {
    height: 100%;
    font-family: 'Roboto', sans-serif;
}

hr {
    width: 100%;
    height: 1px;
    margin: 0;
    border-top: none;
    background-color: $border-color;
}

.vwui-table__actions .vwui-button__text,
.vwui-table__action {
    @media(max-width: 767px) {
        display: none;
    }
}

.vwui-table__actions .vwui-button__icon {
    @media(max-width: 767px) {
        margin: 0;
    }
}

vwui-table-column {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
