.fullscreen-modal {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    max-width: 100%;
    margin: 0;
    overflow: hidden;

    .modal-content {
        border-radius: 0;
        border: 0;
        height: 100%;
    }
}

.media-processing {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    &__header {
        display: flex;
        align-items: center;
        height: 80px;
        background: #fff;
        border-bottom: 1px solid #e8e9f0;
        padding: 0 24px;
        flex-shrink: 0;
    }

    &__title {
        display: flex;
        align-items: center;
        font-size: 16px;
    }

    &__title-label {
        @media(max-width: 767px) {
            display: none;
        }
    }

    &__toggle-selection {
        .vwui-checkbox {
            margin: 0;
        }

        .vwui-checkbox__label {
            display: none;
        }
    }

    &__close-btn {
        display: flex;
        align-items: center;
        background: none;
        border: 0;
        outline: 0;
        color: #a6afca;

        &:hover {
            color: var(--vwui-color-dark);
        }
    }

    &__close-icon {
        margin-right: var(--vwui-size-spacing-sm);
    }

    &__count {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 12px;
        margin-left: var(--vwui-size-spacing-sm);
        height: 24px;
        border-radius: 16px;
        background: #eff1f8;
        font-size: 12px;
        font-weight: var(--vwui-font-weight-semibold);
    }

    &__actions {
        display: flex;
        align-items: center;
        margin-left: auto;

        vwui-button {
            margin-left: var(--vwui-size-spacing-sm);
        }
    }

    &__container {
        display: flex;
        overflow: auto;
        flex-flow: column nowrap;
        @media(min-width: 768px) {
            flex: 1;
            flex-flow: row wrap;
        }
    }

    &__sidebar {
        flex-shrink: 0;
        background: #fff;
        border-right: 1px solid #e8e9f0;
        padding: 1rem;
        order: 1;
        overflow: auto;

        @media(min-width: 768px) {
            order: 0;
            width: 400px;
        }
    }

    &__form {
        display: none;

        &--visible {
            display: block;
        }

        @media(min-width: 768px) {
            display: block;
        }
    }

    &__sidebar-actions {
        @media (min-width: 768px) {
            display: none;
        }

        vwui-button,
        .vwui-button,
        .vwui-button__label {
            display: block;
            width: 100%;
        }
    }

    &__content {
        flex: 1;
        background: #eff1f8;
        overflow: scroll;
        padding: var(--vwui-size-spacing-base);
    }

    &__process-button {
        .vwui-icon {
            @media(max-width: 767px) {
                margin: 0;
            }
        }
    }

    &__process-label {
        @media (max-width: 767px) {
            display: none;
        }
    }
}

.media-processing-grid {
    --media-items-grid-spinner-bg: #eff1f8;
}
