$card-shadow: $beel-shadow-base, 0 -3px 0 0 $beel-color-shadow;

.beel-card {
  width: 100%;
  height: rem(319px);
  box-shadow: $card-shadow;
  background: $beel-color-bg-base;
  border-radius: $beel-border-radius-base;
  border: $beel-border-outside;
  margin-top: rem(3px); // Offset shadow
  margin-bottom: rem(10px);
  position: relative;
  --card-padding: #{$beel-space-component};

  &.is-basic {
    box-shadow: $beel-shadow-base;
    margin-top: 0;
    border-top: $beel-border-outside;
  }

  &.is-small {
    --card-padding: #{$beel-space-m};
  }

  &__header,
  &__content {
    padding: var(--card-padding);
    border-bottom: $beel-border-inside;
  }

  &__header {
    display: flex;
    font-size: $beel-font-size-l;
    font-weight: $beel-font-weight-secondary;
    font-family: $beel-font-secondary;
    align-items: center;
    justify-content: space-between;

    &.has-action {
      cursor: pointer;

      &:hover {
        background-color: $beel-color-bg-hover;
      }
    }

    &.is-selected {
      background-color: $beel-color-bg-hover;
    }
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    padding: $beel-space-component;
  }

  &__scroll {
    max-height: 50vh;
    overflow-y: auto;
    border-bottom: $beel-border-inside;
  }

  &__dot {
    padding-right: rem(5px);
    font-weight: bold;
    vertical-align: bottom;
    padding-bottom: rem(5px);
    font-size: 20px;

  }

  &__textExt {
    padding-top: rem(5px);
  }
  &__body {
    padding: 10px 18px;
  }
  &__top-img {
    width: 100%;
    height: 100%;
    border-top-left-radius: calc(0.25rem - 1px);
    border-top-right-radius: calc(0.25rem - 1px);
    object-fit: contain;
    object-position: center;
    position: relative;
    border: 0;
  }
  &__video {
    width: 100%;
    height: 100%;
    border-top-left-radius: calc(0.25rem - 1px);
    border-top-right-radius: calc(0.25rem - 1px);
    object-fit: cover;
    object-position: center;
    position: relative;
  }
  &__checkbox {
    position: absolute;
    left: rem(10px);
    top: rem(10px);
    z-index: 1;
    transition: 300ms ease-in-out;
  }
  &__checkbox--hidden {
    opacity: 0;
  }
  &__tags {
      position: relative;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      height: rem(32px);
      border-radius: 4px;
      background-color: $beel-color-bg-secondary;
      margin-right: 5px;
      display: flex;
      align-items: center;
      text-align: center;
      padding: 0 1rem;
      opacity: 0.8;
      margin-bottom: 5px;

      &__tag-text {
          font-family: Heebo;
          font-size: 12px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: normal;
          color: #ffffff;
          margin: auto;
          text-align: center;
          max-width: 100px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
      }
  }
}
.beel-card-dynamic {
  width: 100%;
  height: rem(319px);
  box-shadow: $card-shadow;
  background: $beel-color-bg-base;
  border-radius: $beel-border-radius-base;
  border: $beel-border-outside-dynamic;
  margin-top: rem(3px); // Offset shadow
  margin-bottom: rem(10px);
  position: relative;
  --card-padding: #{$beel-space-component};

  &.is-basic {
    box-shadow: $beel-shadow-base;
    margin-top: 0;
    border-top: $beel-color-border-outside-dynamic;
  }

  &.is-small {
    --card-padding: #{$beel-space-m};
  }

  &__header,
  &__content {
    padding: var(--card-padding);
    border-bottom: $beel-border-inside;
  }

  &__header {
    display: flex;
    font-size: $beel-font-size-l;
    font-weight: $beel-font-weight-secondary;
    font-family: $beel-font-secondary;
    align-items: center;
    justify-content: space-between;

    &.has-action {
      cursor: pointer;

      &:hover {
        background-color: $beel-color-bg-hover;
      }
    }

    &.is-selected {
      background-color: $beel-color-bg-hover;
    }
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    padding: $beel-space-component;
  }

  &__scroll {
    max-height: 50vh;
    overflow-y: auto;
    border-bottom: $beel-border-inside;
  }

  &__dot {
    padding-right: rem(5px);
    font-weight: bold;
    vertical-align: bottom;
    padding-bottom: rem(5px);
    font-size: 20px;


  }

  &__textExt {
    padding-top: rem(5px);
  }
  &__body {
    padding: rem(25px);
  }
  &__top-img {
    width: 100%;
    height: 70%;
    border-top-left-radius: calc(0.25rem - 1px);
    border-top-right-radius: calc(0.25rem - 1px);

  }
  &__checkbox {
    position: absolute;
    left: 0;
    top: 0;
  }
  &__tags {
    position: relative;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    height: rem(32px);
    border-radius: 4px;
    background-color: $beel-color-bg-secondary;
    margin-right: 5px;
    display: flex;
    align-items: center;
    text-align: center;
    padding: 0 1rem;
    opacity: 0.8;
    &__tag-text {
      font-family: Heebo;
      font-size: 12px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      color: #ffffff;
      margin: auto;
      text-align: center;
    }
  }
}

.beel-card__tags-holder {
    position: absolute;
    bottom: rem(6px);
    left: rem(6px);
    display: flex;
    flex-wrap: wrap;
}

.beel-card--full {
    margin: 0;
    cursor: pointer;
    transition: border-color 300ms ease-in-out;
    overflow: hidden;

    .media-grid-item__multi-media {
        height: 100%;
    }

    .beel-card__checkbox {
        --radius: 0 0 2px 0;
        left: 0;
        top: 0;
    }

    &.beel-card--active {
        border-color: $beel-color-text-blue;
    }
}

.beel-card__video {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;

    &__play__icon {
        width: 3rem;
        height: 3rem;
    }
}
