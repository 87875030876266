.preview-modal__modal {
    --vwui-modal--max-width: 80%;
    --vwui-modal--max-height: calc(100vh - 3.5rem);
}

.preview-modal__wrapper {
    padding: #{rem(20px)};
    height: calc(100vh - 3.5rem);
}

.preview-modal {
    margin-left: rem(40px);

    &__round-circle-holder {
        display: flex;
        justify-content: flex-end;
        margin-left: rem(16px);

        @media(min-width: 768px) {
            margin: 0;
        }
    }
}

.preview-modal__close {
    width: rem(144px);
    height: rem(144px);
    background: white;
    top: rem(-62px);
    right: rem(-62px);
    border-radius: 50%;
    position: absolute;
    cursor: pointer;

    &:hover {
        .preview-modal__close__icon {
            opacity: 0.75;
        }
    }
}

.preview-modal__close-icon {
    --size: #{rem(12px)};
    --color: #{$beel-color-bg-secondary};

    position: absolute;
    left: rem(40px);
    bottom: rem(40px);
}

.preview-modal__body {
    display: flex;
    justify-content: space-between;
    height: 100%;

    @media(max-width: 767px) {
        flex-flow: column nowrap;
    }
}

.preview-modal__content {
    flex-grow: 1;
    min-width: 0;
    display: flex;
    flex-direction: column;
    height: 100%;
    margin: 0 0 rem(16px) 0;

    @media(min-width: 768px) {
        margin: 0;
        padding: rem(16px);
    }
}

.preview-modal__top {
    height: rem(48px);
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: rem(18px);
    color: $beel-color-text-secondary;

    @media(min-width: 768px) {
        justify-content: space-between;
    }
}

.preview-modal__sidebar {
    background: $beel-color-bg-grey;
    height: 100%;
    border-bottom-right-radius: rem(8px);
    padding: var(--vwui-size-spacing-xxl);
    overflow: auto;
    overflow-scrolling: touch;

    @media(min-width: 768px) {
        width: rem(340px);
        margin-left: rem(40px);
        flex-grow: 0;
        flex-shrink: 0;
    }
}

.preview-modal__media {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    margin: rem(40px) 0;
    min-height: 0;
    position: relative;
}

.preview-modal__media--video {
    .preview-modal__image {
        display: none;
    }

    .preview-modal__video {
        display: block;
    }
}

.preview-modal__video {
    display: none;

    &--error {
        .preview-modal__video-unavailable {
            display: flex;
        }
    }
}

.preview-modal__bottom {
    min-height: 112px;
    width: 100%;
    position: relative;
}

.preview-modal__bottom-spinner {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.preview-modal__content__bottom__layer {
    width: 100%;
    height: 100%;
    overflow-x: scroll;
}

.preview-modal__content__bottom__scroll {
    display: flex;
    flex-wrap: nowrap;
}

.preview-modal__content__bottom__thumbnail {
    width: 75px;
    height: 75px;
    margin-right: 8px;
    object-fit: cover;
    object-position: center;
    cursor: pointer;
}

.preview-modal__content__bottom__thumbnail__owl {
    width: 100%;
    height: 100%;
    border: 2px solid transparent;
    border-radius: rem(6px);
    overflow: hidden;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.preview-modal__content__bottom__thumbnail__checkbox {
    left: rem(2px);
    top: rem(2px);
}

.preview-modal__content__bottom__thumbnail__owl--is-selected {
    border: 2px solid $beel-color-primary-base;
}

.preview-modal__media-element {
    object-fit: contain;
    object-position: center;
    width: 100%;
    height: 100%;

    &--hidden {
        display: none;
    }
}

.preview-modal__video-unavailable {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: none;
    justify-content: center;
    align-items: center;

    &__text {
        color: $white;
        background: $beel-color-nav-overlay;
        padding: 0.25rem 0.5rem;
        border-radius: 0.25rem;
        text-align: center;
        max-width: 20rem;
    }
}

.preview-modal__video-holder {
    position: relative;
    height: 100%;
}

.preview-modal__sidebar-title {
    color: $beel-color-text-secondary;
    font-weight: 500;
    font-size: rem(18px);
}

.preview-modal__sidebar-separator {
    margin: rem(30px) 0;
    height: rem(1px);
    width: 100%;
    background: $beel-color-bg-separator;
}

.preview-modal__carousel {
    .owl-stage-outer::after {
        display: block;
        position: absolute;
        content: '';
        right: 0;
        top: 0;
        bottom: 0;
        width: 40px;
        background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
    }
}

.preview-modal__info-item {
    display: flex;
    flex-direction: column;
    margin-bottom: var(--vwui-size-spacing-md);
}

.preview-modal__info-label {
    display: inline-block;
    font-weight: var(--vwui-font-weight-semibold);
    font-size: var(--vwui-size-font-sm);
    color: var(--vwui-color-neutral-1);
}

.select-all-checkbox {
    display:inline-flex;
    margin-bottom: var(--vwui-size-spacing-md);

    &__text {
        color: $beel-color-text-secondary;
    }
}
