.icon-circle {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    background: var(--icon-circle-bg, rgba(var(--vwui-color-rgb-primary), .1));
    color: var(--icon-circle-color, var(--vwui-color-primary));
    border-radius: var(--vwui-size-radius-round);
    width: 3rem;
    height: 3rem;
    transition: background .15s , color .15s;

    --icon-size: 1.25rem;
}
