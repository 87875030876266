
$beel-font-size-root: calc(strip-unit($beel-font-size-default) / 16) * 1em;

$beel-font-size-base: 1rem;
$beel-font-size-m: rem(17px);
$beel-font-size-l: rem(20px);
$beel-font-size-xl: rem(36px);
$beel-font-size-xxl: rem(48px);

$beel-font-size-h1: $beel-font-size-xxl;
$beel-font-size-h2: $beel-font-size-xl;
$beel-font-size-h3: $beel-font-size-l;
$beel-font-size-h4: $beel-font-size-m;
$beel-font-size-h5: $beel-font-size-base;
$beel-font-size-h6: $beel-font-size-base;
