.image-editor__top-bar {
    width: 100%;
    display: flex;
    align-items: center;
    height: #{rem(45px)};
    flex-grow: 0;
    flex-shrink: 0;
    padding: 0 0 0 rem(16px);
    border-top-right-radius: rem(8px);
    border-top-left-radius: rem(8px);
    justify-content: space-between;
    position: relative;
}

.image-editor__content {
    padding: 2rem 5rem 5rem 5rem;
    flex-grow: 1;
    flex-shrink: 1;
}

.image-editor__wrapper {
    display: flex;
    flex-direction: column;
}

.image-editor__size {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.image-editor__canvas-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.image-editor__canvas {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background: transparent;

    &--ui {
        cursor: crosshair;
        left: -#{rem(10px)};
        right: -#{rem(10px)};
        bottom: -#{rem(10px)};
        top: -#{rem(10px)};
    }
}

.image-editor__canvas__loader {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.image-editor__controls {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40rem;
    left: 50%;
    margin-left: -20rem;
    height: #{rem(45px)};
    bottom: 1.5rem;
    margin-top: calc(#{rem(45px)} / -2);
    position: absolute;
}

.image-editor__canvas__size-label__container {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
}

.image-editor__canvas__size-label {
    --width: #{rem(100px)};
    --height: #{rem(30px)};

    width: var(--width);
    height: var(--height);
    margin-top: calc(var(--height) / -2);
    margin-left: calc(var(--width) / -2);
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    color: $beel-color-text-base;
    background: var(--vwui-color-neutral-4);
    border-radius: var(--vwui-size-radius-lg);
    will-change: transform;
}

.image-editor__control {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.image-editor__control__label {
    display: inline-block;
    margin-top: 0.25rem;
    text-align: center;
}

.image-editor-confirm {
    padding: var(--vwui-size-spacing-sm) var(--vwui-size-spacing-xxs);
    color: var(--vwui-color-dark);
    font-size: var(--vwui-size-font-base);

    &__content {
        margin-bottom: var(--vwui-size-spacing-lg);
    }

    &__action {
        vwui-button {
            display: block;
        }
    }
}
