.media-info-tags__list {
    display: flex;
    flex-wrap: wrap;
    margin-top: var(--vwui-size-spacing-xxs);
    margin-bottom: var(--vwui-size-spacing-xs);
}

.media-info-tags__tag,
.media-info-tags__tag-btn {
    display: inline-flex;
    align-items: center;
    font-size: var(--vwui-size-font-xs);
    font-weight: var(--vwui-font-weight-semibold);
    background: var(--vwui-color-neutral-3);
    border-radius: var(--vwui-size-radius-sm);
    padding: var(--vwui-size-spacing-xxs) var(--vwui-size-spacing-xs);
}

.media-info-tags__tag {
    margin-right: var(--vwui-size-spacing-xxs);
    margin-bottom: var(--vwui-size-spacing-xxs);

    &:hover {
        background: var(--vwui-color-neutral-2);
        text-decoration: none;
    }
}

.media-info-tags__tag-btn {
    background: transparent;
    border: 0;
    color: var(--vwui-color-dark);

    --icon-spacing: var(--vwui-size-spacing-xxs);

    &--large {
        font-size: var(--vwui-size-font-base);

        --icon-spacing: var(--vwui-size-spacing-xs);
    }

    &:hover {
        color: var(--vwui-color-primary);
        background: var(--vwui-color-light);
    }

    vwui-icon,
    vwui-spinner {
        --size: 1em;

        margin-right: var(--icon-spacing);
    }
}

.media-info-tags__tag-select {
    margin-top: var(--vwui-size-spacing-xs);
    margin-bottom: var(--vwui-size-spacing-xs);
}
