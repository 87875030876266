.card-grid-items {
    --grid-item-min-width: 400px;
    --grid-gap: 2rem;
    --grid-repeat: auto-fit;

    @media screen and (min-width: 1024px) {
        --grid-item-min-width: calc(33.33% - var(--grid-gap));
    }

    .vwui-grid__header {
        display: none;
    }
}

.card-project__title {
    font-size: var(--vwui-size-font-lg);
    font-weight: var(--vwui-font-weight-semibold);
    color: var(--vwui-color-primary);
    margin-bottom: var(--vwui-size-spacing-base);
}

.card-project__subtitle {
    margin-bottom: var(--vwui-size-spacing-xxs);
}

.card-project__subtitle strong {
    font-weight: var(--vwui-font-weight-semibold);
}

.card-project__button-group {
    display: flex;
}

.card-project__button-group vwui-button:first-child {
    margin-right: var(--vwui-size-spacing-xs);
}

.card-project .is-danger {
    --bg: var(--vwui-color-danger);
    --shadow: var(--vwui-shadow-base);
    --color: var(--vwui-color-light);
}

.card-project vwui-button.is-primary {
    --shadow: var(--vwui-shadow-base);
}
