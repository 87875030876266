@import 'login-overlay';

vwui-toast {
    pointer-events: none;
}

.header-action-buttons {
    vwui-button {
        margin-left: var(--vwui-size-spacing-sm);
    }
}

.is-hidden {
    display: none;
}

.ng-value {
    padding-right: .5rem;
}

.vwui-button::before {
    transition: none !important;
}

.vwui-button--large-icon {
    .vwui-button__icon {
        --size: 1.5rem;
    }
}

.vwui-file-input > label > vwui-button {
    width: 100%;
}

// Fix ng-select clear functionality due to vwui padding right
.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
    padding-right: 0;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-disabled {
    opacity: 0.4;
}
