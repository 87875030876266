.toast {
    background-color: theme-color(dark);
}
.toast-success {
    background-color: theme-color(success);
}
.toast-error {
    background-color: theme-color(danger);
}
.toast-info {
    background-color: theme-color(primary);
}
.toast-warning {
    background-color: theme-color(warning);
}
