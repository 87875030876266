app-file-item {
    display: block;
}

// Styling based on vwui-file-input component
.file-item {
    width: 100%;
    height: 3rem;
    display: inline-flex;
    align-items: center;
    padding: var(--vwui-size-spacing-xs) var(--vwui-size-spacing-sm);
    background: var(--vwui-color-light);
    color: var(--vwui-color-dark);
    border-radius: var(--vwui-size-radius-sm);
    font-size: 15px;

    &:not(&--borderless) {
        border: var(--vwui-border-size-base) var(--vwui-border-type-base) var(--vwui-color-neutral-3);
        box-shadow: var(--vwui-shadow-base);
    }

    &:hover {
        background: var(--vwui-color-neutral-4);
        cursor: pointer;
    }

    &__type-indicator {
        border-radius: 2px;
        line-height: 1.67;
        min-width: 2.5rem;
        padding: 0.125rem 0;
        font-size: 0.75rem;
        font-weight: 500;
        text-align: center;
        margin-right: 0.75rem;
        text-transform: uppercase;
        color: var(--vwui-color-light);
        background: var(--vwui-color-primary);
    }

    &__file-name {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        font-weight: normal;
        color: var(--vwui-color-dark);
        margin-right: 0.5rem;
    }

    &__remove-button {
        padding: 0 0.25rem;
        cursor: pointer;
        color: var(--vwui-color-primary);
        margin-left: auto;
        font-size: 1.5rem;

        &:hover {
            color: var(--vwui--color--dark);
        }
    }

    &__spinner {
        padding: 0 0.25rem;
        width: 1.5rem;
        cursor: pointer;
        margin-left: auto;
    }
}
