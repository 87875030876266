$beel-space-s: rem(10px);
$beel-space-m: rem(20px);
$beel-space-l: rem(30px);
$beel-space-xl: rem(50px);

$beel-space-component-horizontal: rem(35px);
$beel-space-component-horizontal-s: rem(20px);

$beel-space-component-vertical: rem(20px);
$beel-space-component-vertical-s: rem(12px);

$beel-space-component: $beel-space-component-vertical $beel-space-component-horizontal;
$beel-space-component-s: $beel-space-component-vertical-s $beel-space-component-horizontal-s;

$beel-space-grid-gutter: $beel-space-l;
$beel-space-grid-gutter-lg: $beel-space-xl;

$beel-spaces: (
    none: 0,
    s: $beel-space-s,
    m: $beel-space-m,
    l: $beel-space-l,
    xl: $beel-space-xl
);
