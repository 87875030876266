/* You can add global styles to this file, and also import other style files */
@import "scss/global";
@import 'styles/core/_index';
@import 'styles/settings/_index';
@import 'styles/tools/_index';
@import 'styles/vendor/_index';
@import 'styles/generic/_index';
@import 'styles/elements/_index';
@import 'styles/components/_index';
@import 'styles/utilities/_index';
@import 'styles/app-specific/_index';
