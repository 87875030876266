app-media-items-grid {
    display: block;
    width: 100%;
    height: 100%;
}

.media-items-grid {
    position: relative;
    width: 100%;
    height: var(--grid-height);
    transition: opacity .15s;

    &.init .media-items-grid-item {
        opacity: 1;
        transition: opacity .25s;
        transition-delay: 1.25s;
    }

    &--loading {
        opacity: .5;
        margin-bottom: var(--vwui-size-spacing-xxl);
    }
}

.media-items-spinner {
    position: fixed;
    top: 25vh;
    left: calc(50% + calc(var(--vwui-size-component-nav) / 2));
    padding: var(--vwui-size-spacing-xxl);
    transform: translateX(-50%);
    transition: opacity .15s;
    pointer-events: none;

    &.visible {
        opacity: 1;
    }

    &.invisible {
        opacity: 0;
    }

    &__content {
        background: var(--media-items-grid-spinner-bg, #fff);
        padding: var(--vwui-size-spacing-sm);
        border-radius: var(--vwui-size-radius-lg);
    }
}

.media-items-load-more {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: var(--vwui-size-spacing-lg);
}

.media-items-add-btn {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px dashed var(--vwui-color-neutral-2);
    border-radius: var(--vwui-size-radius-sm);
    cursor: pointer;

    &:hover {
        --icon-circle-bg: var(--vwui-color-primary);
        --icon-circle-color: var(--vwui-color-light);

        border-color: var(--vwui-color-neutral-1);
    }

    input {
        display: none;
    }

    &__title {
        font-size: var(--vwui-size-font-lg);
        font-weight: var(--vwui-font-weight-semibold);
        color: var(--vwui-color-dark);
        margin-top: var(--vwui-size-spacing-lg);
    }

    &__description {
        color: var(--vwui-color-neutral-1);
        margin-top: var(--vwui-size-spacing-xs);
        text-align: center;
    }
}

.media-item-tags {
    display: flex;
    flex-wrap: wrap;
}

.media-item-tag {
    color: var(--vwui-color-light);
    background: rgba(var(--vwui-color-rgb-dark), .8);
    font-size: var(--vwui-size-font-sm);
    font-weight: var(--vwui-font-weight-semibold);
    padding: var(--vwui-size-spacing-xxs) var(--vwui-size-spacing-xs);
    margin-right: var(--vwui-size-spacing-xs);
    margin-bottom: var(--vwui-size-spacing-xxs);
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}
