.empty-media-card {
    height: rem(350);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.empty-media-card__image {
    width: auto;
}

.empty-media-card__description {
    color: $beel-color-placeholder-base;
}
