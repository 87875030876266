.beel-grid {
    background: $beel-color-bg-base;
    margin-top: rem(10px);
}

.beel-grid__search vwui-search {
    --height: 50px;
}

.beel-grid__column {
    @extend .col-lg-4;
    @extend .col-xl-3;
    @extend .col-md-6;
    @extend .col-sm-4;
}
