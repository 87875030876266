app-file-item {
    display: block;
}

.image-download-document__label {
    font-weight: bold;
    font-size: var(--vwui-size-font-sm);
    color: var(--vwui-color-neutral-1);
    margin-left: var(--vwui-size-spacing-sm);
}
