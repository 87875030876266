app-image-modal-thumbnail-carousel {
    .owl-nav {
        display: none !important;
    }
}

.image-modal-thumbnail-carousel {
    position: relative;
}

.thumbnail-carousel-nav {
    position: absolute;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
}

.thumbnail-carousel-nav-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: 0;
    width: 2.5rem;
    height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--vwui-color-neutral-1);
    opacity: 0;
    transition: opacity .15s;

    &:hover {
        color: var(--vwui-color-dark);
    }

    vwui-icon {
        --size: 1.5rem;
    }

    &--prev {
        left: -2.25rem;
    }

    &--next {
        right: -2.5rem;
    }

    &--visible {
        opacity: 1;
    }
}
