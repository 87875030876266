.action-button {
    width: rem(48px);
    height: rem(48px);
    border-radius: 50%;
    border: rem(1px) solid var(--vwui-color-neutral-3);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    outline: 0;
    position: relative;

    &:hover {
        background-color: $beel-color-bg-hover;
    }

    &--active {
        border-color: $beel-color-primary-base;
        border-width: rem(3px);

        .action-button__icon {
            --color: #{$beel-color-primary-base};
        }
    }

    &__spinner {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        transition: all var(--vwui-transition-base);
    }
}

.action-button__icon {
    --color: #{$beel-color-text-secondary};
    --size: #{rem(16px)};
}
