.fs-modal {
    width: 100%;
    max-width: none;
    background-color: #eff1f8;
    z-index: 10000;
    margin: 0;
    min-height: 100%;
}

.modal {
    z-index: 10000;
}

vwui-modal .modal {
    display: block;
}

.modal-backdrop {
    background: rgba($beel-color-dark-modal, .90);
}

.modal-backdrop.show {
    opacity: 0.5;
    z-index: 9999;
}

.beel-modal-header {
    display: flex;
    border-bottom: $beel-border-inside;
    padding: 0 $beel-space-component-horizontal;
    height: rem(80px);
    justify-content: space-between;
    align-items: center;
    margin-left: rem(1px);
    background: white;
    flex-grow: 0;
    flex-shrink: 0;

    &__count-box {
        height: rem(24px);
        border-radius: rem(16px);
        background-color: #eff1f8;
        padding: 0 rem(12px);
        font-family: Heebo;
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        color: #00384c;
        display: inline-flex;
        align-items: center;
    }

    &__text-space {
        margin-left: rem(30px);
        margin-right: rem(20px);
        font-family: Heebo;
        font-size: rem(16px);
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: #00384c;
    }
}

.beel-modal-content {
    flex-grow: 1;
    flex-shrink: 1;
    display: flex;
    flex-direction: column;
}

.modal-footer {
    &__no-border {
        .modal-footer {
            border-top: 0;
        }
    }

    &__action-left {
        .modal-footer {
            justify-content: flex-start;
        }
    }
}

// Bootstrap override
.modal-content {
    border-radius: $beel-border-radius-base;
    border: 0;
}


.beel-modal__top {
    display: flex;
    align-items: center;
}

.beel-modal__close {
    cursor: pointer;
    --color: #{$beel-color-icon};

    &:hover {
        opacity: 0.8;
    }
}

.beel-modal-header__drop-zone {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}


