.invisible {
    opacity: 0;
}

.clickable {
    cursor: pointer;
}

@each $size, $length in $spacers {
    .flex-gap-#{$size} { gap: $length !important; }
}
