.media-grid-album {
    --grid-gap: var(--vwui-size-spacing-sm);
    --grid-item-min-width: 16.75rem;
    --grid-repeat: auto-fill;

    display: grid;
    grid-gap: 1rem;
    margin: 1rem 0;
    max-height: 6rem;
    overflow: hidden;

    &--expanded {
        max-height: 60rem;
    }

    grid-template-columns: repeat(var(--grid-repeat, auto-fit), minmax(var(--grid-item-min-width), 1fr)); // Responsive grid sizing with minimum grid-item-size

    &__expand-button {
        display: flex;
        justify-content: center;
        align-items: center;

        background: none;
        border: none;
        padding: 0;

        color: var(--vwui-color-primary);
        font-size: rem(15px);

        &--expanded vwui-icon {
            transform: scaleY(-1);
        }

        vwui-icon {
            --size: 1em;
        }
        &:hover {
            text-decoration: underline;
        }
    }

    &__item {
        border-radius: 8px;
        background-image: linear-gradient(to right, #0091c2, #00b2c0);
        height: 6rem;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        color: white;
        padding: 1.5rem;
        position: relative;
        &:hover {
            text-decoration: none;
            color: white;
        }

        &__thumbnail {
            flex-shrink: 0;
            width: 3rem;
            height: 3rem;
            margin-right: 1rem;
            border-radius: 8px;
            background: white;
            box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.08), 0 1px 0 0 rgba(0, 0, 0, 0.12);
            object-fit: cover;
        }

        &__text {
            overflow: hidden;
        }

        &__title {
            font-size: 1rem;
            font-weight: 500;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }

        &__subtitle {
            font-size: rem(13px);
            line-height: 1.85;
        }

        &__menu {
            position: absolute;
            top: rem(8px);
            right: rem(8px);

            vwui-btn-icon {
                --icon-color: var(--vwui-color-light);
            }
        }
    }
}
