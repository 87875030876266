.drag-drop-overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba($beel-color-dark-modal, 0.9);
    align-items: center;
    justify-content: center;
    display: none;
    margin: 0;
    z-index: 2;
}

.drag-drop-overlay--active {
    display: flex;
}

.drag-drop-overlay__texts {
    display: flex;
    flex-direction: column;
}

.drag-drop-overlay__texts__title {
    font-weight: 500;
    color: white;
    font-size: rem(15px);
}

.drag-drop-overlay__texts__sub-title {
    color:  $beel-color-text-label-secondary;
    font-size: rem(15px);
}

.drag-drop-overlay__button {
    width: rem(56px);
    height: rem(56px);
    border-radius: 50%;
    box-shadow: $beel-shadow-base;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 2;
}

.drag-drop-overlay__button-wrapper {
    position: relative;
    margin-right: rem(16px);
}

.drag-drop-overlay__content-holder {
    display: flex;
    align-items: center;
}

.drag-drop-overlay__button__icon {
    --size: rem(14px);
}

.drag-drop-overlay__particles {
    position: absolute;
    bottom: rem(16px);
    left: 0;
    right: 0;
}

.drag-drop-overlay__particle {
    border-radius: 50%;
    background: $beel-color-text-label-secondary;
    width: rem(2px);
    height: rem(2px);
    position: absolute;
    animation: particles 1s ease-in infinite;
}

.drag-drop-overlay__zone {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 2;
}

@keyframes particles {
    0% {
        opacity: 0;
    }
    20% {
        opacity: 1;
        transform: translate(0, 15%);
    }
    100% {
        opacity: 0;
        transform: translate(0, 5000%);
    }
}
