.progressbar {
    display: flex;
    margin: 1.25rem;
    background: rgba($beel-color-bg-secondary, .95);
    border-radius: 0.5rem;
    padding: rem(12px) rem(26px);
    color: #fff;
    font-size: rem(14px);
    position: fixed;
    right: rem(10px);
    bottom: rem(10px);
}

.tile-progressbar span {
    display: block;
    background: #fff;
    width: 0;
    height: 100%;
    -webkit-transition: all 1.5s cubic-bezier(0.23, 1, 0.32, 1);
    -moz-transition: all 1.5s cubic-bezier(0.23, 1, 0.32, 1);
    -o-transition: all 1.5s cubic-bezier(0.23, 1, 0.32, 1);
    transition: all 1.5s cubic-bezier(0.23, 1, 0.32, 1);
    border-radius: rem(1px);
}

.tile-progressbar {
    height: rem(2px);
    background: rgba(255, 255, 255, 0.24);
    margin: 0;
    z-index: 11111111111;
    border-radius: rem(1px);
    width: 20vw;

    @media(min-width: 768px) {
        width: 7rem;
    }
}

.showPointer {
    cursor: pointer;
    display: flex;
    margin-left: auto;
}

.progress-bar__file-name {
    color: white;
    font-weight: 500;
    font-size: rem(15px);
    margin-right: 1rem;

    @media(max-width: 767px) {
        display: none;
    }
}

.progress-bar__info {
    color: $beel-color-text-label;
    margin-right: 1rem;
}

.progress-bar__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 1rem;
}

.progress-bar__close {
    --size: #{rem(12px)};
    --color: #{$beel-color-text-label};
    flex-shrink: 0;
    cursor: pointer;

    &:hover {
        opacity: 0.95;
    }
}

.progress-bar__bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.progress-bar__progress {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.progress-bar__progress__text {
    margin-left: rem(24px);
    font-weight: 500;
    font-size: rem(15px);
}
