$description-height: rem(60px);

.media-grid-item {
    display: flex;
    flex-direction: column;

    &__file-icon {
        position: absolute;
        top: 5px;
        right: 5px;
        color: var(--vwui-color-light);
    }
}

.media-grid-item__multi-media {
    flex-grow: 1;
    height: calc(100% - #{$description-height});
    position: relative;
}

.media-grid-item__description {
    flex-grow: 0;
    flex-shrink: 0;
    height: $description-height;

    &--project-name {
        font-weight: 500;
        margin: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &--date {
        font-family: Heebo;
        font-size: var(--vwui-size-font-sm);
        font-weight: 500;
        color: var(--vwui-color-neutral-1);
    }
}

.media-grid-item__loading-image {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    border: 0;
    box-shadow: none;
}

.media-grid-generic-item {
    --thumbnail: "";
    position: relative;
    border: 1px solid var(--vwui-color-neutral-3);
    border-radius: 0.5rem;
    height: 265px;
    cursor: pointer;
    background-image: linear-gradient(to top, rgba(0, 0, 0, .65), rgba(0, 0, 0, 0)), var(--thumbnail);
    background-size: cover;
    &--has-thumbnail {
        color: var(--vwui-color-light);
    }
    &--has-thumbnail &__badge {
        background: var(--vwui-color-dark);
    }

    &__icon-container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 75%;

        vwui-icon {
            --size: 74px;
        }
    }

    &__title-container {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 0 var(--vwui-size-spacing-lg) var(--vwui-size-spacing-lg);
    }

    &__title {
        font-size: 1rem;
        font-weight: var(--vwui-font-weight-semibold);
        margin-bottom: var(--vwui-size-spacing-xs);
    }
    &__subtitle {
        color: var(--vwui-color-neutral-1);
        font-size: var(--vwui-size-font-sm);
        font-weight: var(--vwui-font-weight-semibold);
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    &__badge {
        display: inline-block;
        font-size: 12px;
        font-weight: var(--vwui-font-weight-semibold);
        background: var(--vwui-color-neutral-4);
        padding: 0 var(--vwui-size-spacing-xs);
        border-radius: 1rem;
        white-space: nowrap;
    }
}
