$move-transition: transform 300ms ease-in-out;

.beel-sidemenu {
    box-sizing: border-box;
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    left: rem(285px);
    width: rem(301px);
    height: 100%;
    background-color: $beel-color-bg-base;

    &__item {
        background-color: $beel-color-bg-base;
        border-bottom: $beel-boder-round;
        align-items: center;
        height: rem(56px);
    }
    &__org-name {
        font-family: Heebo;
        font-size: rem(14px);
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        color: $beel-color-text-secondary;
    }

    &__title {
        margin-left: rem(12.5px);
        font-family: Heebo;
        font-size: rem(15px);
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.6;
        letter-spacing: normal;
        color: $beel-color-text-secondary;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
    &__rotate {
        transform: rotate(180deg);
        width: rem(15.7px);
        color: #a6afca;
    }

    &__show-more {
        box-shadow: var(--vwui-shadow-base);
        background: rgba(white, 0.7);
        border-radius: var(--vwui--radius--md);
        padding: 1rem 1.5rem;
        display: flex;
        align-items: center;
        font-size: var(--vwui-size-font-lg);
        color: $beel-color-text-secondary;
    }
    &__down-arrow {
        margin-left: auto;
    }
}
.beel-sidemenu--no-border {
    border: none;
    background-color: $beel-color-bg-base;
    border-bottom: none;
}

.beel-sidemenu-icon {
    box-sizing: border-box;
    display: block;
    position: absolute;
    right: 0;
    top: rem(36px);
    left: rem(272px);
    z-index: 999;
    cursor: pointer;
    &__icon {
        display: flex;
        width: rem(24px);
        height: rem(24px);
        align-items: center;
        justify-content: center;
        --icon-fill: #{$beel-color-text-label};
        --icon-size: #{rem(23px)};
    }

    &__round-circle {
        display: flex;
        width: rem(24px);
        height: rem(24px);
        border-radius: 50%;
        background-color: $beel-color-bg-base;
        border: $beel-boder-round;
    }
}

.project-list-menu {
    position: relative;
    width: 100%;
    height: 100%;

    &__search {
        padding: var(--vwui-size-spacing-lg);
    }

    &__header {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 150px;
        display: flex;
        flex-direction: column;
    }

    &__toolbar {
        display: flex;
        align-items: center;
        overflow: hidden;
        margin: 0 var(--vwui-size-spacing-lg);
        padding: var(--vwui-size-spacing-md) 0;
    }

    &__content {
        position: absolute;
        top: 150px;
        left: 0;
        right: 0;
        bottom: 0;
        overflow-y: auto;
        padding: 0 var(--vwui-size-spacing-lg) var(--vwui-size-spacing-lg);
    }

    &__title {
        display: flex;
        align-items: center;
        flex: 1;
        transform: translateX(-34px);
        margin-right: -34px;
        transition: transform .15s ease-in-out, margin-right .15s ease-in-out;
        overflow: hidden;

        &--show-back {
            transform: translateX(0);
            margin-right: 0;
        }
    }

    &__title-label {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &__back-btn {
        margin-right: var(--vwui-size-spacing-xxs);
    }

    &__create-btn {
        transform: translateX(calc(100% + 8px));
        transition: transform .15s ease-in-out;
        margin-right: 4px;

        &--visible {
            transform: translateX(0);
        }
    }

}

.project-list-header {
    color: var(--vwui-color-neutral-1);
    font-size: 12px;
    text-transform: uppercase;
    margin-bottom: var(--vwui-size-spacing-sm);
    position: sticky;
    top: 0;
    background: var(--vwui-color-light);
}

.project-list {
    margin-bottom: var(--vwui-size-spacing-lg);
}

.vista-create-list {
    margin-bottom: var(--vwui-size-spacing-lg);
}

.vista-create-list-header {
    color: var(--vwui-color-neutral-1);
    font-size: 12px;
    text-transform: uppercase;
    margin-bottom: var(--vwui-size-spacing-sm);
    position: sticky;
    top: 0;
    background: var(--vwui-color-light);
}

app-project-list-item {
    outline: 0;

    &.active {
        color: var(--vwui-color-primary);
    }
}

.project-list-item {
    --icon-color: var(--vwui-color-neutral-1);
    --icon-transform-hover: translateX(.25rem);

    display: flex;
    align-items: center;
    height: 56px;
    border-bottom: var(--vwui-border-light);
    cursor: pointer;
    outline: 0;

    &__label {
        flex: 1;
        font-weight: var(--vwui-font-weight-regular);
    }

    &__icon {
        --size: 12px;
        --color: var(--icon-color);

        flex-shrink: 0;
        transition: transform .15s ease-in-out;
    }

    &:hover {
        .project-list-item__icon {
            transform: var(--icon-transform-hover);
        }
    }

    &--show-more {
        --icon-color: var(--vwui-color-primary);
        --icon-transform-hover: translateY(.25rem);

        color: var(--vwui-color-primary);

    }
}

.project-list-spinner {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 56px;
}

.vwui-tooltip-label {
    z-index: 999999;
}
