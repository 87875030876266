app-icon {
    display: inline-flex;
    align-items: center;
    pointer-events: none;
}

.beel-icon {
    display: inline-flex;
    align-items: center;

    svg {
        width: var(--icon-size, 1em);
        height: var(--icon-size, 1em);
        stroke: var(--icon-stroke, transparent);
        fill: var(--icon-fill, currentColor);
    }
}