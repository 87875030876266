$btn-size: 195px;
$btn-border-radius: rem(6px);

.beel-btn {
  // CSS Custom properties for easy modifying different button states
  --btn-bg: #{$beel-color-bg-linear-gradient};
  --btn-bg-hover: #{$beel-color-bg-linear-gradient};
  --btn-border: #{$beel-border-base};
  --btn-border-color: #{$beel-color-border-outside};
  --btn-border-color-hover: #{$beel-color-border-inside};
  --btn-space: #{$beel-space-component-vertical-s} #{$beel-space-component-horizontal-s};
  --btn-color: #{$beel-color-text-contrast};
  --btn-color-hover: #{$beel-color-text-contrast};
  --btn-font-weight: #{$beel-font-weight-base};
  --btn-focus-shadow-color: #{rgba($beel-color-border-outside, 0.75)};
  --icon-fill: #{$beel-color-text-label};
  --icon-size: #{rem(20px)};
  --icon-border-color: #{$beel-color-border-outside};
  --loader-color: #{rgba($beel-color-text-label, 0.2)};
  --loader-color-spinner: #{rgba($beel-color-text-label, 1)};

  display: inline-flex;
  position: relative;
  height: rem(48px);
  border-radius: $btn-border-radius;
  vertical-align: top;
  align-items: center;
  justify-content: center;
  transition: all $beel-transition-base;

  border: var(--btn-border) var(--btn-border-color);
  background: var(--btn-bg);
  padding: var(--btn-space);
  color: var(--btn-color);
  font-weight: var(--btn-font-weight);
  will-change: transform;
  opacity: 0.3;
  @include text-ellipsis();

  &:active {
    transform: scale(0.994) translateZ(0);
    box-shadow: none;
  }

  &.has-icon {
    padding-left: rem($btn-size + 15);

    app-icon {
      display: flex;
      position: absolute;
      top: 0;
      left: 0;
      border-right: var(--btn-border) var(--icon-border-color);
      background: var(--btn-bg-hover);
      transform: scale(0.99999, 0.99999) translateZ(0); // Fix bug in Chrome if transform scale is applied

      width: rem($btn-size);
      height: rem($btn-size);

      @include flex-center();
    }
  }

  &:hover {
    text-decoration: none;
    background: var(--btn-bg-hover);
    color: var(--btn-color-hover);
    border: var(--btn-border) var(--btn-border-color-hover);
  }

  &:disabled {
    // CSS Vars must always be important if button is disabled
    --btn-bg: #{$beel-color-bg-disabled} !important;
    --btn-bg-hover: #{$beel-color-bg-disabled} !important;
    --btn-color: #{$beel-color-text-disabled} !important;
    --btn-color-hover: #{$beel-color-text-disabled} !important;
    --btn-border-color: transparent !important;
    --btn-border-color-hover: transparent !important;

    cursor: not-allowed;
  }

  &.is-primary {
    --btn-bg: #{$beel-color-primary-base};
    --btn-bg-hover: #{$beel-color-primary-dark};
    --btn-color: #{$beel-color-text-contrast};
    --btn-color-hover: #{$beel-color-text-contrast};
    --btn-border-color: transparent;
    --btn-border-color-hover: #{$beel-color-primary-dark};
    --btn-font-weight: #{$beel-font-weight-secondary};
    --btn-focus-shadow-color: #{rgba($beel-color-primary-base, 0.25)};
    --icon-border-color: var(--btn-border-color-hover);
    --icon-fill: var(--btn-color);
    --loader-color: #{rgba($beel-color-text-contrast, 0.2)};
    --loader-color-spinner: #{rgba($beel-color-text-contrast, 1)};
  }

  &.is-success {
    --btn-bg: #{$beel-color-success-base};
    --btn-bg-hover: #{$beel-color-success-dark};
    --btn-color: #{$beel-color-text-contrast};
    --btn-color-hover: #{$beel-color-text-contrast};
    --btn-border-color: transparent;
    --btn-border-color-hover: #{$beel-color-success-dark};
    --btn-font-weight: #{$beel-font-weight-secondary};
    --btn-focus-shadow-color: #{rgba($beel-color-success-base, 0.25)};
    --icon-border-color: var(--btn-border-color-hover);
    --icon-fill: var(--btn-color);
    --loader-color: #{rgba($beel-color-text-contrast, 0.2)};
    --loader-color-spinner: #{rgba($beel-color-text-contrast, 1)};
  }

  &.is-danger {
    --btn-border-color-hover: #{$beel-color-danger-base};
    --icon-fill: #{$beel-color-danger-base};
  }

  &.is-back {
    --btn-bg: transparent;
    --btn-bg-hover: transparent;
    --icon-border-color: transparent;

    &.has-icon {
      padding-left: rem($btn-size);
    }
  }

  &.is-block {
    width: 100%;

    &.has-icon {
      padding-left: rem($btn-size);
    }
  }

  &.has-loader {
    padding-left: rem($btn-size + 15);
  }

  &__icon {
    display: flex;
    width: rem(16px);
    height: rem(16px);
    align-items: center;
    justify-content: center;
    --icon-fill: #{$beel-color-text-contrast};
    --icon-size: #{rem(23px)};
    opacity: 0.7;
    padding-right: rem(15px);
    padding-bottom: rem(5px);
  }
}

.beel-btn__loader {
  $loader-width: rem(20px);
  $loader-border-width: rem(3px);

  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: rem($btn-size);
  height: rem($btn-size);
  border-right: var(--btn-border) var(--icon-border-color);

  &::after {
    display: block;
    content: "";
    width: $loader-width;
    height: $loader-width;
    border-top: $loader-border-width solid var(--loader-color);
    border-right: $loader-border-width solid var(--loader-color);
    border-bottom: $loader-border-width solid var(--loader-color);
    border-left: $loader-border-width solid var(--loader-color-spinner);
    border-radius: 100%;
    animation: btn-loader 1.1s infinite linear;
  }
  

  @keyframes btn-loader {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }
}

.beel-btn-no-bg {
  background: none;
  border: none;
}

.beel-icon-btn {
  background: none;
  border: 0;
  color: $beel-color-text-label;
  --icon-size: #{rem(20px)};

  &:hover {
    color: $beel-color-text-base;
  }
}


