$input-height: rem(45px);
$select-bg-position: calc(100% - #{$beel-space-component-horizontal-s});

.beel-form-group {
    .beel-label {
        display: block;
        color: $beel-color-text-label;
        font-weight: $beel-font-weight-secondary;
        margin-bottom: $beel-space-s;
    }

    .beel-textarea {
        padding: $beel-space-component-vertical-s $beel-space-component-horizontal-s;
    }

    &__description {
        display: block;
        margin-top: rem(5px);
        color: $beel-color-text-label;

        .has-error & {
            color: $beel-color-danger-base;
        }
    }

    &__select {
        position: relative;
        box-shadow: 0 1px 2px 0 rgba(216, 222, 229, .5);

        .beel-select {
          //  @include icon-bg('select', $select-bg-position, center);
            background-size: rem(20px) rem(20px);
        }
    }

    &.has-error {
        label {
            color: $beel-color-danger-base;
        }

        .beel-input {
            border-color: $beel-color-danger-base;
        }
    }
}

.beel-input-control {
    .beel-input {
        display: none;
    }

    .beel-input:checked ~ span::before {
        border-color: $beel-color-primary-base;
    }

    .beel-input:disabled ~ span::before {
        background-color: $beel-color-bg-disabled;
    }

    span {
        position: relative;
        display: inline-flex;
        align-items: center;
        transition: all $beel-transition-base;
        padding-left: rem(30px);

        @include disable-text-selection();
    }

    &.is-checkbox,
    &.is-radio {
        span::before {
            display: inline-block;
            position: absolute;
            left: 0;
            content: "";
            width: rem(20px);
            height: rem(20px);
            border-radius: $beel-border-radius-s;
            border: $beel-border-outside;
            margin-right: rem(10px);
            transition: all $beel-transition-base;
        }
    }

    &.is-checkbox {
        .beel-input:checked ~ span::after {
            transform: scale(1);
        }

        span::after {
            display: inline-block;
            position: absolute;
            left: 4px;
            content: "";
            width: rem(14px);
            height: rem(14px);
            transform: scale(0);
            transition: all $beel-transition-timing-slow $beel-transition-easeInOutBack;

          //  @include icon-bg('checkbox', center, center);
        }
    }

    &.is-radio {
        .beel-input:checked ~ span::after {
            transform: scale(1);
        }

        span::before {
            border-radius: 100%;
        }

        span::after {
            display: inline-block;
            position: absolute;
            left: 6px;
            content: "";
            width: rem(8px);
            height: rem(8px);
            border-radius: 100%;
            background-color: $beel-color-primary-base;
            transform: scale(0);
            transition: all $beel-transition-timing-slow $beel-transition-easeInOutBack;
        }
    }

    &.is-toggle {
        .beel-input:checked ~ span::before {
            background-color: $beel-color-primary-base;
        }

        .beel-input:checked ~ span::after {
            transform: translate(calc(200% - 8px), -50%);
        }

        .beel-input:disabled ~ span::after {
            background-color: $beel-color-border-outside;
        }

        .beel-input:disabled ~ span {
            cursor: not-allowed;
        }

        span {
            padding-left: 0;
            color: $beel-color-text-label;
            font-weight: $beel-font-weight-secondary;
        }

        span::before {
            content: "";
            position: absolute;
            width: rem(70px);
            height: rem(35px);
            background-color: $beel-color-bg-inactive;
            border-radius: rem(50px);
            cursor: pointer;
            padding-left: 0;
        }

        span::after {
            content: "";
            position: absolute;
            top: 50%;
            left: 8px;
            width: rem(20px);
            height: rem(20px);
            background-color: $beel-color-bg-base;
            border-radius: 100%;
            transform: translate(0, -50%);
            transition: transform $beel-transition-timing-slow $beel-transition-easeInOutBack;
            will-change: transform;
        }

        &.has-label {
            margin-bottom: rem(45px);

            span::before {
                top: rem(32px);
            }

            span::after {
                top: rem(50px);
            }
        }
    }
}

.beel-input,
.beel-select,
.beel-textarea {
    width: 100%;
    border-radius: $beel-border-radius-base;
    border: $beel-border-outside;
    padding: 0 $beel-space-component-horizontal-s;
    background-color: $beel-color-bg-base;
    color: $beel-color-text-secondary;
    transition: all $beel-transition-base;
    outline: 0;

    @include remove-appearance();

    &:focus {
        border-color: $beel-color-primary-base;
    }

    &:disabled {
        background-color: $beel-color-bg-disabled;
        cursor: not-allowed;
        color: $beel-color-text-disabled;
    }
}

.beel-input,
.beel-select {
    height: $input-height;
}

.beel-input.is-plain {
    padding-left: 0;
    background: none;
    border: none;
    color: $beel-color-text-base;
    cursor: default;
}

.ng-placeholder {
    top: 0;
    height: 100%;
    padding-bottom: 0;
    display: inline-flex;
    align-items: center;
}

.ng-value-icon.left {
    padding-left: rem(12px);
}

.ng-input {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    top: 0 !important;
}
