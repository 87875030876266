.choice-group {
    display: flex;
    width: 100%;
}

.choice-item {
    position: relative;
    margin-right: .5rem;
    flex: 1;

    input {
        position: absolute;
        height: 1px;
        width: 1px;
        opacity: 0;
    }

    input:checked ~ .choice-item__label {
        --radio-inner-scale: 1;
        --radio-outer-border-color: var(--vwui-color-primary);
        --radio-outer-bg: var(--vwui-color-primary);

        border-color: var(--vwui-color-primary);
    }

    &:last-child {
        margin-right: 0;
    }

    &__label {
        --radio-inner-scale: 0;
        --radio-outer-border-color: var(--vwui-color-neutral-2);
        --radio-outer-bg: var(--vwui-color-light);

        display: flex;
        align-items: center;
        height: 3.5rem;
        border: 1px solid var(--vwui-color-neutral-3);
        border-radius: var(--vwui-size-radius-sm);
        padding: 0 var(--vwui-size-spacing-md);
        transition: border-color .15s ease-in-out;
        cursor: pointer;

        &::before,
        &::after {
            content: "";
            display: block;
            position: absolute;
            top: 50%;
            border-radius: 100%;
            transition: all .15s ease-in-out;
        }

        &::before {
            right: var(--vwui-size-spacing-md);
            width: 20px;
            height: 20px;
            border: 1px solid var(--radio-outer-border-color);
            background: var(--radio-outer-bg);
            transform: translateY(-50%);
        }

        &::after {
            width: 6px;
            height: 6px;
            right: 10px;
            background: var(--vwui-color-light);
            transform: translate(calc((100% + 6px) * -1), -50%) scale(var(--radio-inner-scale));
        }
    }
}
