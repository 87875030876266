.media-grid {
    height: 100%;
    background: $beel-color-bg-base;
    margin-top: rem(32px);
}

.media-grid-filter {
    display: flex;
    border-top: var(--vwui-border-light);
    border-bottom: var(--vwui-border-light);
    padding: var(--vwui-size-spacing-base) 0;
    flex-flow: column wrap;
    @media(min-width: 768px) {
        flex-flow: row wrap;
    }
}

.media-grid__holder {
    --grid-gap: var(--vwui-size-spacing-sm);
    --grid-item-min-width: 16.75rem;
    --grid-repeat: auto-fill;

    display: grid;
    grid-gap: 1rem;
    padding: 1rem 0;

    grid-template-columns: repeat(var(--grid-repeat, auto-fit), minmax(var(--grid-item-min-width), 1fr)); // Responsive grid sizing with minimum grid-item-size
}

.media-grid__empty-state {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(rgba(251, 251, 253, 0) 75%, rgba(245, 246, 250, 0.24));
    border: solid 1px var(--vwui-border-color-light);
    border-radius: var(--vwui--radius--md);
    box-shadow: var(--vwui-shadow-base);
    margin: 1.5rem 0;
    height: 265px;

    &__image {
        margin-bottom: var(--vwui-spacing--lg);
        max-width: 100px;
    }
    &__message {
        color: var(--vwui-color-neutral-1);
    }
}
