$beel-border-radius-base: rem(5px);
$beel-border-radius-s: rem(3px);
$beel-border-radius-l: rem(10px);
$beel-border-radius-white: rem(4px);

$beel-border-base: 1px solid;
$beel-border-dashed: 1px dashed;

$beel-border-outside-dynamic: $beel-border-base $beel-color-border-outside-dynamic;

$beel-border-outside: $beel-border-base $beel-color-border-outside;
$beel-border-inside: $beel-border-base $beel-color-border-inside;
$beel-border-nav-active: 3px solid $beel-color-primary-base;

$beel-border-uploadbox:$beel-border-dashed $beel-color-border-uploadbox;

$beel-boder-round:$beel-border-base $beel-color-border-outside-round;
