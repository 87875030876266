.app-alert {
    --alert-color: var(--vwui-color-warning);
    --alert-color-rgb: var(--vwui-color-rgb-warning);

    padding: var(--vwui-size-spacing-md);
    background: rgba(var(--alert-color-rgb), .025);
    border: 2px solid var(--alert-color);
    color: var(--alert-color);
    border-radius: var(--vwui-size-radius-md);
    font-weight: var(--vwui-font-weight-semibold);
}
