$beel-transition-timing: .15s;
$beel-transition-timing-fast: .1s;
$beel-transition-timing-slow: .25s;
$beel-transition-ease: ease-in-out;
$beel-transition-easeInOutBack: cubic-bezier(0.68, -0.55, 0.265, 1.55);

$beel-transition-base: $beel-transition-timing-fast $beel-transition-ease;

$beel-placeholder-animation: placeholder-background ease-in-out infinite 1s;

@keyframes placeholder-background {
    0% {
        background-color: $beel-color-placeholder-base;
    }

    50% {
        background-color: $beel-color-placeholder-transition;
    }

    100% {
        background-color: $beel-color-placeholder-base;
    }
}

@keyframes fade-in {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}
