// Brand color
$beel-color-brand: #d8dee5;
// Card color
$beel-color-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.04);
// Contextual colors
$beel-color-primary-base: #008cbd;
$beel-color-primary-dark: #007da9;
$beel-color-success-base: #50c892;
$beel-color-success-dark: #47af80;
$beel-color-danger-base: #db5353;
$beel-color-warning-base: #ff9e7e;

// Border colors
$beel-color-border-outside: #d8dee5;
$beel-color-border-inside: #e9edf3;
$beel-color-border-dark: #222f3e;
$beel-color-border-uploadbox: #c2c9dd;
$beel-color-border-outside-dynamic: #008cbd;
$beel-color-border-outside-round:#e1e5f1;

// Background colors
$beel-color-bg-body: #f3f4f6;
$beel-color-bg-base: #fff;
$beel-color-bg-hover: #f2f6fa;
$beel-color-bg-disabled: #e9edf3;
$beel-color-bg-inactive: #e9edf3;
$beel-color-bg-linear-gradient: linear-gradient(to right, #008cbd 0%, #00a6bd 100%);
$beel-color-bg-white: #e5f4f7;
$beel-color-icon-bg: #ffffff;
$beel-color-bg-secondary: #00384c;
$beel-color-bg-pb: linear-gradient(to right, #008cbd 50%, #00a6bd 100%);
$beel-color-dark-modal: #05152E;
$beel-color-bg-grey: #EFF1F8;
$beel-color-bg-separator: #E5E7F0;

// Text colors
$beel-color-text-base: #222f3e;
$beel-color-text-primary: $beel-color-primary-base;
$beel-color-text-label: #a6afca;
$beel-color-text-disabled: #9aa5b5;
$beel-color-text-contrast: #ffffff;
$beel-color-text-blue: #008cbc;
$beel-color-text-secondary: #00384c;
$beel-color-text-label-secondary: #a6afca;
$beel-color-text-separator: #BECADC;
$beel-color-text-login-green: #00374C;

// Placeholder colors
$beel-color-placeholder-base: #e1e5f1;
$beel-color-placeholder-transition: #d8dee5;

// Icon colors
$beel-color-icon: #9aa5b5;
$beel-color-chevron: #a6afca;
$beel-color-arrow: #a6afca;

// Shadow colors
$beel-color-shadow-base: rgba(0, 0, 0, 0.05);

// Nav colors
$beel-color-nav-active: rgba(0, 88, 167, 0.05);
$beel-color-nav-overlay: rgba(0, 0, 0, 0.15);

// Color map to generate specific utility helper classes
$beel-colors: (
  primary: $beel-color-primary-base,
  success: $beel-color-success-base,
  danger: $beel-color-danger-base,
  label: $beel-color-text-label-secondary,
  contrast: $beel-color-text-contrast
);
