.media-items-grid-item {
    position: absolute;
    overflow: hidden;
    cursor: pointer;
    border-radius: var(--vwui-size-radius-sm);
    transform: translate3d(var(--translate-x), var(--translate-y), 0);
    width: 100%;
    height: var(--height);
    transition: transform .15s;
    @media(min-width: 768px) {
        width: var(--width);
    }

    &:hover, &--selected {
        --info-opacity: 1;

        img {
            transform: scale(1.06, 1.06) translate3d(0, 0, 0);
        }
    }

    img {
        transition: transform .35s ease-in-out;
    }

    img.hidden {
        opacity: 0;
    }

    &--add-btn {
        width: 250px;
    }

    &--loaded {
        --skeleton-opacity: 0;
        --skeleton-visibility: invisible;
        --image-opacity: 1;
    }

    &__image {
        opacity: var(--image-opacity, 0);
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &__info {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background: linear-gradient(to top, rgba(0, 0, 0, .65), rgba(0, 0, 0, 0));
        padding: var(--vwui-size-spacing-sm) var(--vwui-size-spacing-base);
        opacity: var(--info-opacity, 0);
        transition: opacity .25s ease-in-out;
    }

    &__info-description {
        margin-top: var(--vwui-size-spacing-xs);
    }

    &__info-title {
        font-weight: var(--vwui-font-weight-semibold);
        color: var(--vwui-color-light);
    }

    &__info-subtitle {
        color: var(--vwui-color-neutral-1);
        font-weight: var(--vwui-font-weight-semibold);
        font-size: var(--vwui-size-font-sm);
    }

    &__file {
        position: absolute;
        top: var(--vwui-size-spacing-md);
        right: var(--vwui-size-spacing-md);
        color: var(--vwui-color-light);
        opacity: var(--info-opacity, 0);
        transition: opacity .25s ease-in-out;
    }

    &__video {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: var(--info-opacity, 0);
        transition: opacity .25s ease-in-out;
    }

    &__video-icon {
        width: 3rem;
        height: 3rem;
    }

    &__selected {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        border: 1px solid var(--vwui-color-primary);
        border-radius: var(--vwui-size-radius-sm);
        background: rgba(0, 0, 0, .25);
        opacity: 0;

        .media-items-grid-item--selected & {
            transition: opacity .15s;
            opacity: 1;
        }

        vwui-checkbox {
            position: absolute;
            top: -1px;
            left: -1px;
        }
    }

    &__skeleton {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: var(--skeleton-opacity, 1);
        visibility: var(--skeleton-visibility, visible);
        transition: opacity .15s, visibility .15s;
        background: var(--vwui-color-neutral-4);
        border: 1px solid var(--vwui-color-neutral-3);
        z-index: 10;

        &::before {
            content: '';
            display: block;
            position: absolute;
            left: -150px;
            top: 0;
            height: 100%;
            width: 150px;
            background: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, var(--vwui-color-neutral-3) 50%, rgba(0, 0, 0, 0) 100%);
            animation: image-skeleton 1s cubic-bezier(0.4, 0.0, 0.2, 1);
            animation-iteration-count: infinite;
            animation-duration: 1.5s;
        }
    }
}

@keyframes image-skeleton {
    from {
        left: -150px;
    }
    to   {
        left: 100%;
    }
}
