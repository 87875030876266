.create-project__top {
    display: flex;
    justify-content: space-between;
    align-items: center;


    vwui-btn-icon {
        --icon-size: #{rem(12px)};
    }
}
