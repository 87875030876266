.add-picture__holder {
    display: flex;
    width: 100%;
    height: 100%;
    flex-grow: 1;
}

.add-picture__sidebar {
    flex-shrink: 0;
    flex-grow: 0;
    width: rem(400px);
    background: white;
}

.add-picture__content {
    flex-grow: 1;
    max-height: 100%;
    background: var(--vwui-color-neutral-4);
}

.add-picture__delete {
    --bg: var(--vwui-color-neutral-4);
    --border-color: var(--vwui-btn-secondary--border-color,var(--vwui-color-secondary));
    --shadow: var(--vwui-btn-secondary--shadow,none);
}

.add-picture__top {
    --vwui-icon--size: #{rem(16px)};
}
