.beel-search {
    display: flex;
    position: relative;
    border-bottom: $beel-border-inside;
    padding: 0 $beel-space-component-horizontal-s 0 0;
    align-items: center;
    width: 100%;
    height: rem(50px);
    max-width: 100%;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(0, 56, 76, 0.04);
    border: $beel-border-outside;
    background-color: #ffffff;
    @media(min-width: 768px) {
        width: rem(267px);
    }

    &__icon {
        position: absolute;
        top: 50%;
        right: $beel-space-s;
        transform: translateY(-50%);
        pointer-events: none;

        app-icon {
            --icon-size: #{rem(20px)};
            --icon-fill: #{$beel-color-text-label};

            .beel-icon {
                display: block;
            }
        }
    }

    .beel-input {
        width: 100%;
        border: 0;
    }

    .beel-input:focus ~ .beel-search__icon {
        app-icon {
            --icon-fill: #{$beel-color-text-primary};
        }
    }
}

.beel-search__filter {
    margin: rem(8px) 0 0;
    @media(min-width: 768px) {
        margin: 0 0 0 rem(20px);
    }

    vwui-datepicker {
        width: 14rem;
        --height: 50px;
        color: var(--vwui-color-light);
    }
    .vwui-datepicker__input::placeholder {
        color: var(--vwui-color-neutral-1);
    }

    .file-type-indicator {
        border-radius: 2px;
        line-height: 1.67;
        padding: 0 6px;
        font-size: 0.75rem;
        font-weight: 500;
        margin-left: 0.75rem;
        text-transform: uppercase;
        color: var(--vwui-color-light);
        background: var(--vwui-color-primary);
    }

    .clear {
        padding: 0 var(--vwui-size-spacing-xs);
    }
}
