/**
  Mixin to easy generate CSS Variable from a SCSS map

  Example:

  varName: value
 */
 @mixin css-variable($map) {
    @if (is-map($map)) {
      // Map over each key value pair and generate CSS variable
      @each $name, $value in $map {
        --#{$name}: #{$value};
      }
    }
  }
  