.media-grid-item-placeholder__image {
    width: 100%;
    height: 100%;
    border-top-left-radius: calc(0.25rem - 1px);
    border-top-right-radius: calc(0.25rem - 1px);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $beel-color-placeholder-base;
    background-image: url(/assets/icons/image.svg);
    background-repeat: no-repeat;
    background-position: center;
    animation: $beel-placeholder-animation;
}
