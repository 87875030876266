input[type="checkbox"] {
  cursor: pointer;
  appearance: none;
  outline: 0;
  background: lightgray;
  width: 24px;
  height: 24px;
  display: none;

  &:checked {
    width: 24px;
    height: 24px;
    background-color: #008cbd;
    display: block !important;
  }
  &:hover {
    filter: brightness(90%);
    background-color: #008cbd;
  }
  &:disabled {
    background: #e6e6e6;
    opacity: 0.6;
    pointer-events: none;
  }
  &:after {
    content: "";
    position: relative;
    left: 40%;
    top: 20%;
    width: 15%;
    height: 40%;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    display: none;
  }
  &:checked:after {
    display: block;
  }
  &:disabled:after {
    border-color: #7b7b7b;
  }
}

h1 {
  font-family: $beel-font-base;
  font-size: $beel-font-size-h1;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: $beel-color-text-secondary;
}
h2 {
  font-family: $beel-font-base;
  font-size: $beel-font-size-h2;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: $beel-color-text-secondary;
}
h3 {
  font-family: $beel-font-base;
  font-size: $beel-font-size-h3;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: $beel-color-text-secondary;
}
h4 {
  font-family: $beel-font-base;
  font-size: $beel-font-size-h4;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: $beel-color-text-secondary;
  align-items: center;
  justify-content: center;
}
h5 {
  font-family: $beel-font-base;
  font-size: $beel-font-size-h5;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: $beel-color-text-secondary;
}
h6 {
  font-family: $beel-font-base;
  font-size: $beel-font-size-h6;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: $beel-color-text-secondary;
}

p {
  font-family: $beel-font-base;
  font-size: rem(15px);
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  color: $beel-color-text-secondary;
}
