$btn-size: 103px;
$btn-border-radius: rem(4px);

.beel-pb {
  --btn-bg: #{$beel-color-bg-pb};
  --btn-bg-hover: #{$beel-color-bg-pb};
  --btn-border: #{$beel-border-base};
  --btn-border-color: #{$beel-color-border-outside};
  --btn-border-color-hover: #{$beel-color-border-inside};
  --btn-space: #{$beel-space-component-vertical-s} #{$beel-space-component-horizontal-s};
  --btn-color: #{$beel-color-text-contrast};
  --btn-color-hover: #{$beel-color-text-contrast};
  --btn-font-weight: #{$beel-font-weight-base};
  --btn-focus-shadow-color: #{rgba($beel-color-border-outside, 0.75)};
  --icon-fill: #{$beel-color-text-label};
  --icon-size: #{rem(20px)};
  --icon-border-color: #{$beel-color-border-outside};
  --loader-color: #{rgba($beel-color-text-label, 0.2)};
  --loader-color-spinner: #{rgba($beel-color-text-label, 1)};

  display: inline-flex;
  width: rem(123px);
  position: relative;
  height: rem(48px);
  border-radius: $btn-border-radius;
  vertical-align: top;
  align-items: center;
  justify-content: center;
  transition: all $beel-transition-base;
  box-shadow: inset 0 -1px 0 0 #e8e9f0;
  background-color: #ffffff;
  border: var(--btn-border) var(--btn-border-color);
  background: var(--btn-bg);
  padding: var(--btn-space);
  color: var(--btn-color);
  font-weight: var(--btn-font-weight);
  will-change: transform;
  margin-right: 20px;

  @include text-ellipsis();
  &:focus {
    box-shadow: 0 0 0 3px var(--btn-focus-shadow-color);
  }

  &:active {
    transform: scale(0.994) translateZ(0);
    box-shadow: none;
  }

  &.has-icon {
    padding-left: rem($btn-size + 15);

    app-icon {
      display: flex;
      position: absolute;
      top: 0;
      left: 0;
      border-right: var(--btn-border) var(--icon-border-color);
      background: var(--btn-bg-hover);
      transform: scale(0.99999, 0.99999) translateZ(0); // Fix bug in Chrome if transform scale is applied

      width: rem($btn-size);
      height: rem($btn-size);

      @include flex-center();
    }
  }

  &:hover {
    text-decoration: none;
    background: var(--btn-bg-hover);
    color: var(--btn-color-hover);
    border: var(--btn-border) var(--btn-border-color-hover);
  }

  &:disabled {
    // CSS Vars must always be important if button is disabled
    --btn-bg: #{$beel-color-bg-disabled} !important;
    --btn-bg-hover: #{$beel-color-bg-disabled} !important;
    --btn-color: #{$beel-color-text-disabled} !important;
    --btn-color-hover: #{$beel-color-text-disabled} !important;
    --btn-border-color: transparent !important;
    --btn-border-color-hover: transparent !important;

    cursor: not-allowed;
  }

  &.is-primary {
    --btn-bg: #{$beel-color-primary-base};
    --btn-bg-hover: #{$beel-color-primary-dark};
    --btn-color: #{$beel-color-text-contrast};
    --btn-color-hover: #{$beel-color-text-contrast};
    --btn-border-color: transparent;
    --btn-border-color-hover: #{$beel-color-primary-dark};
    --btn-font-weight: #{$beel-font-weight-secondary};
    --btn-focus-shadow-color: #{rgba($beel-color-primary-base, 0.25)};
    --icon-border-color: var(--btn-border-color-hover);
    --icon-fill: var(--btn-color);
    --loader-color: #{rgba($beel-color-text-contrast, 0.2)};
    --loader-color-spinner: #{rgba($beel-color-text-contrast, 1)};
  }

  &.is-success {
    --btn-bg: #{$beel-color-success-base};
    --btn-bg-hover: #{$beel-color-success-dark};
    --btn-color: #{$beel-color-text-contrast};
    --btn-color-hover: #{$beel-color-text-contrast};
    --btn-border-color: transparent;
    --btn-border-color-hover: #{$beel-color-success-dark};
    --btn-font-weight: #{$beel-font-weight-secondary};
    --btn-focus-shadow-color: #{rgba($beel-color-success-base, 0.25)};
    --icon-border-color: var(--btn-border-color-hover);
    --icon-fill: var(--btn-color);
    --loader-color: #{rgba($beel-color-text-contrast, 0.2)};
    --loader-color-spinner: #{rgba($beel-color-text-contrast, 1)};
  }

  &.is-danger {
    --btn-border-color-hover: #{$beel-color-danger-base};
    --icon-fill: #{$beel-color-danger-base};
  }

  &.is-back {
    --btn-bg: transparent;
    --btn-bg-hover: transparent;
    --icon-border-color: transparent;

    &.has-icon {
      padding-left: rem($btn-size);
    }

    &__icon {
      display: flex;
      background: $beel-color-bg-base;
      width: rem(35px);
      height: rem(35px);
      border-radius: 100%;
      border: 1px solid $beel-color-border-inside;
      align-items: center;
      justify-content: center;

      --icon-fill: #{$beel-color-success-base};
      --icon-size: #{rem(23px)};
    }
  }

  &.is-block {
    width: 100%;

    &.has-icon {
      padding-left: rem($btn-size);
    }
  }

  &.has-loader {
    padding-left: rem($btn-size + 15);
  }
}

.bg-white-gray-btn {
    --btn-bg: #{$beel-color-bg-base};
    --btn-bg-hover: #{$beel-color-bg-base};
    --btn-border: #{$beel-border-base};
    --btn-border-color: #{$beel-color-border-outside};
    --btn-border-color-hover: #{$beel-color-border-inside};
    --btn-space: #{$beel-space-component-vertical-s} #{$beel-space-component-horizontal-s};
    --btn-color: #{$beel-color-text-label-secondary};
    --btn-color-hover: #{$beel-color-text-label-secondary};
    --btn-font-weight: #{$beel-font-weight-base};
    --btn-focus-shadow-color: #{rgba($beel-color-border-outside, 0.75)};
    --icon-fill: #{$beel-color-text-label};
    --icon-size: #{rem(20px)};
    --icon-border-color: #{$beel-color-border-outside};
    --loader-color: #{rgba($beel-color-text-label, 0.2)};
    --loader-color-spinner: #{rgba($beel-color-text-label, 1)};
  
    display: inline-flex;
    width: rem(123px);
    position: relative;
    height: rem(48px);
    border-radius: $btn-border-radius;
    vertical-align: top;
    align-items: center;
    justify-content: center;
    transition: all $beel-transition-base;
    box-shadow: inset 0 -1px 0 0 #e8e9f0;
    background-color: #ffffff;
    border: var(--btn-border) var(--btn-border-color);
    background: var(--btn-bg);
    padding: var(--btn-space);
    color: var(--btn-color);
    font-weight: var(--btn-font-weight);
    will-change: transform;
    margin-right: 20px;
  
    @include text-ellipsis();
    &:focus {
      box-shadow: 0 0 0 3px var(--btn-focus-shadow-color);
    }
  
    &:active {
      transform: scale(0.994) translateZ(0);
      box-shadow: none;
    }
  
    &.has-icon {
      padding-left: rem($btn-size + 15);
  
      app-icon {
        display: flex;
        position: absolute;
        top: 0;
        left: 0;
        border-right: var(--btn-border) var(--icon-border-color);
        background: var(--btn-bg-hover);
        transform: scale(0.99999, 0.99999) translateZ(0); // Fix bug in Chrome if transform scale is applied
  
        width: rem($btn-size);
        height: rem($btn-size);
  
        @include flex-center();
      }
    }
  
    &:hover {
      text-decoration: none;
      background: var(--btn-bg-hover);
      color: var(--btn-color-hover);
      border: var(--btn-border) var(--btn-border-color-hover);
    }
  
    &:disabled {
      // CSS Vars must always be important if button is disabled
      --btn-bg: #{$beel-color-bg-disabled} !important;
      --btn-bg-hover: #{$beel-color-bg-disabled} !important;
      --btn-color: #{$beel-color-text-disabled} !important;
      --btn-color-hover: #{$beel-color-text-disabled} !important;
      --btn-border-color: transparent !important;
      --btn-border-color-hover: transparent !important;
  
      cursor: not-allowed;
    }
  
    &.is-primary {
      --btn-bg: #{$beel-color-primary-base};
      --btn-bg-hover: #{$beel-color-primary-dark};
      --btn-color: #{$beel-color-text-contrast};
      --btn-color-hover: #{$beel-color-text-contrast};
      --btn-border-color: transparent;
      --btn-border-color-hover: #{$beel-color-primary-dark};
      --btn-font-weight: #{$beel-font-weight-secondary};
      --btn-focus-shadow-color: #{rgba($beel-color-primary-base, 0.25)};
      --icon-border-color: var(--btn-border-color-hover);
      --icon-fill: var(--btn-color);
      --loader-color: #{rgba($beel-color-text-contrast, 0.2)};
      --loader-color-spinner: #{rgba($beel-color-text-contrast, 1)};
    }
  
    &.is-success {
      --btn-bg: #{$beel-color-success-base};
      --btn-bg-hover: #{$beel-color-success-dark};
      --btn-color: #{$beel-color-text-contrast};
      --btn-color-hover: #{$beel-color-text-contrast};
      --btn-border-color: transparent;
      --btn-border-color-hover: #{$beel-color-success-dark};
      --btn-font-weight: #{$beel-font-weight-secondary};
      --btn-focus-shadow-color: #{rgba($beel-color-success-base, 0.25)};
      --icon-border-color: var(--btn-border-color-hover);
      --icon-fill: var(--btn-color);
      --loader-color: #{rgba($beel-color-text-contrast, 0.2)};
      --loader-color-spinner: #{rgba($beel-color-text-contrast, 1)};
    }
  
    &.is-danger {
      --btn-border-color-hover: #{$beel-color-danger-base};
      --icon-fill: #{$beel-color-danger-base};
    }
  
    &.is-back {
      --btn-bg: transparent;
      --btn-bg-hover: transparent;
      --icon-border-color: transparent;
  
      &.has-icon {
        padding-left: rem($btn-size);
      }
  
      &__icon {
        display: flex;
        background: $beel-color-bg-base;
        width: rem(35px);
        height: rem(35px);
        border-radius: 100%;
        border: 1px solid $beel-color-border-inside;
        align-items: center;
        justify-content: center;
  
        --icon-fill: #{$beel-color-success-base};
        --icon-size: #{rem(23px)};
      }
    }
  
    &.is-block {
      width: 100%;
  
      &.has-icon {
        padding-left: rem($btn-size);
      }
    }
  
    &.has-loader {
      padding-left: rem($btn-size + 15);
    }
  
}
