.user-info {
    box-shadow: var(--vwui-shadow-base);
    background: rgba(white, 0.7);
    border-radius: var(--vwui--radius--md);
    padding: 1rem 1.5rem;
    margin: 0 var(--vwui-size-spacing-lg);
    display: flex;
    align-items: center;
    font-size: var(--vwui-size-font-lg);
}

.user-info__avatar {
    object-position: center;
    object-fit: cover;
    width: rem(24px);
    height: rem(24px);
    margin-right: 1rem;
    border-radius: 50%;
}

.user-info__logout {
    margin-left: auto;
}

.user-info__badgeSpace {
    margin-left: rem(10px);
}

.user-info__initials {
    width: 24px;
    height: 24px;
    background: var(--vwui--color--primary);
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--vwui--color--light);
    border-radius: 50%;
    font-size: var(--vwui-size-font-sm);
    margin-right: var(--vwui-size-spacing-sm);
}
