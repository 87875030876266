$theme-colors: (
  primary: #497cab,
  primary-dark: #3b648a,
  success: #50c892,
  success-dark: #47af80,
  danger: #db5353,
  warning: #ff9e7e,
  light: #ffffff,
  dark: #00384c,
  pale-gray: #d8dee5
);

$body-color: map-get($theme-colors, dark);

$border-color: map-get($theme-colors, pale-gray);

$btn-border-radius: 9999px;

$btn-primary-color: map-get($theme-colors, primary);
$btn-primary-bg: transparentize(map-get($theme-colors, primary), 0.1);
