.beel-upload-box {
    --box-border-radius: #{$beel-border-radius-base};
    --box-border: #{$beel-border-uploadbox};
    height: rem(319px);
    border-top: 0;
    border-radius: var(--box-border-radius);
    border: var(--box-border);
    padding-top: rem(80px);
    padding-bottom: rem(80px);
    margin: 0;
    display: block;
    cursor: pointer;
    outline: none;

    &__box_content {
        text-align: center;
    }
    &__icon_bg {
        display: block;
        border-radius: 50px;
        margin: 0 auto;
        width: rem(48px);
        height: rem(48px);
        background-color: rgba(0, 140, 189, 0.05);
        color: #008cbd;
        vertical-align: middle;
        padding-top: rem(17px);
        padding-bottom: rem(17px);
        font-size: rem(12px);
    }

    &__text-header {
        width: 108px;
        height: 24px;
        font-family: Heebo;
        font-size: 15px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.6;
        letter-spacing: normal;
        color: $beel-color-text-secondary;
    }

    &__drag-drop-text {
        width: 160px;
        height: 48px;
        font-family: Heebo;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.71;
        letter-spacing: normal;
        text-align: center;
        color: $beel-color-text-label;
    }

    &__choose {
        display: none
    }
}
